import React from "react"
import Title from "../components/Title"
import contactStyles from "./contact.module.scss"
import pageStyles from "./page.module.scss"
import { Formik, Form, Field, ErrorMessage } from "formik"
import mail from "../assets/mail.png"
import whatsapp from "../assets/whatsapp.svg"
import telegram from "../assets/telegram.svg"
import facebook from "../assets/facebook.svg"
import instagram from "../assets/instagram.svg"

const ContactPage = () => (
  <>
    <div className={contactStyles.container}>
      <Title>Contacto</Title>
      <div className={contactStyles.wrapper}>
        <div className={contactStyles.col}>
          <details>
            <summary>Polanco Masaryk</summary>
            <p>
              Presidente Masaryk 61 - 901B <br /> Col. Polanco, Miguel Hidalgo,
              México D.F.
            </p>

            <div className={contactStyles.map}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3762.556740471104!2d-99.1837347!3d19.4315488!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f8acb1b568ef%3A0x418e9c27c5a8301a!2sAv.%20Pdte.%20Masaryk%2061%2C%20Polanco%2C%20Polanco%20V%20Secc%2C%20Miguel%20Hidalgo%2C%2011560%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1sen!2smx!4v1636088703845!5m2!1sen!2smx"
                width="600"
                height="450"
                allowfullscreen=""
                frameBorder="0"
                loading="lazy"
              />
            </div>
          </details>

          <details>
            <summary>Insurgentes Sur</summary>
            <p>
              Insurgentes Sur 1863 - 302B <br /> Col. Guadalupe Inn, Alvaro
              Obregón, México D.F.
            </p>

            <div className={contactStyles.map}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3764.3258229110475!2d-99.18752968459587!3d19.355039686928425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fff410c29855%3A0x41344e720fd26bc6!2sAv.%20Insurgentes%20Sur%201863-302b%2C%20Guadalupe%20Inn%2C%20%C3%81lvaro%20Obreg%C3%B3n%2C%2001020%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1sen!2smx!4v1636086094403!5m2!1sen!2smx"
                width="600"
                height="450"
                allowfullscreen=""
                frameBorder="0"
                loading="lazy"
              />
            </div>
          </details>

          <details>
            <summary>Santa Fe</summary>
            <p>
              Av. Santa Fe 505 Mezanine Oficina 2B <br /> Col. Santa Fe,
              Cuajimalpa, México CDMX{" "}
            </p>

            <div className={contactStyles.map}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3764.258499926537!2d-99.2762161845958!3d19.357956586926694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d200ca340196c3%3A0x59ffdbbf915d17cb!2sAv%20Sta%20Fe%20505%2C%20Lomas%20de%20Santa%20Fe%2C%20Contadero%2C%20Cuajimalpa%20de%20Morelos%2C%2001219%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1sen!2smx!4v1636086142811!5m2!1sen!2smx"
                width="600"
                height="450"
                allowfullscreen=""
                frameBorder="0"
                loading="lazy"
              />
            </div>
          </details>

          <details>
            <summary>Tecamachalco</summary>
            <p>
              Fuente de Pirámides 1 - 501B <br /> Lomas de Tecamachalco,
              Naucalpan, Estado de México.
            </p>

            <div className={contactStyles.map}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.6460130908786!2d-99.22850058459503!3d19.427694886887142!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d201877123ff69%3A0x48631fb5a272ac3b!2sFuente%20de%20Las%20Pir%C3%A1mides%201-501b%2C%20Lomas%20de%20Tecamachalco%2C%2053950%20Naucalpan%20de%20Ju%C3%A1rez%2C%20M%C3%A9x.!5e0!3m2!1sen!2smx!4v1636086182387!5m2!1sen!2smx"
                width="600"
                height="450"
                allowfullscreen=""
                frameBorder="0"
                loading="lazy"
              />
            </div>
          </details>

          <details>
            <summary>Condesa</summary>
            <p>
              Av. Tamaulipas 150 A Piso 13 <br /> Col. Condesa, Cuauhtémoc
              México D.F.
            </p>

            <div className={contactStyles.map}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.0774692389396!2d-99.17804168459516!3d19.409058486897624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff426087168d%3A0xa80428066405c666!2sAv.%20Tamaulipas%20150A%2C%20Hip%C3%B3dromo%2C%20Cuauht%C3%A9moc%2C%2006100%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1sen!2smx!4v1636086230986!5m2!1sen!2smx"
                width="600"
                height="450"
                allowfullscreen=""
                frameBorder="0"
                loading="lazy"
              />
            </div>
          </details>

          <details>
            <summary>Paseo Reforma</summary>
            <p>
              Paseo de la Reforma 42 - 1er Piso Oficina A, <br /> Col. Centro,
              Cuauhtémoc, México D.F.{" "}
              <div className={contactStyles.map}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.5048823275974!2d-99.15459288459486!3d19.433787186883606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f8d34068ac7d%3A0xf041babddbc9458b!2sAv.%20Paseo%20de%20la%20Reforma%2042%2C%20Cuauht%C3%A9moc%2C%2006040%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1sen!2smx!4v1636086265158!5m2!1sen!2smx"
                  width="600"
                  height="450"
                  allowfullscreen=""
                  frameBorder="0"
                  loading="lazy"
                />
              </div>
            </p>
          </details>
        </div>
        <div className={contactStyles.col}>
          <p>
            Si deseas mayor información o cotizar algún producto, mándanos un
            mensaje a:
          </p>
          <div className={pageStyles.contactItem}>
            <img src={whatsapp} />
            <a href="https://wa.me/5215532388549">55 3238 8549</a>
          </div>
          <div className={pageStyles.contactItem}>
            <img src={instagram} />
            <a href="https://www.instagram.com/kronosseguros/">
              @kronosseguros
            </a>
          </div>
          <div className={pageStyles.contactItem}>
            <img src={facebook} />
            <a href="https://www.facebook.com/kronosseguros">@kronosseguros</a>
          </div>
          <div className={pageStyles.contactItem}>
            <img src={telegram} />
            <a href="https://t.me/kronosseguros">@kronosseguros</a>
          </div>
          <div className={pageStyles.contactItem}>
            <img src={mail} />
            <a href="mailto:contacto@kronosseguros.com.mx">
              contacto@kronosseguros.com.mx
            </a>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  </>
)

export default ContactPage

// <Formik
//       initialValues={{ email: "", password: "" }}
//       validate={values => {
//         let errors = {}
//         if (!values.email) {
//           errors.email = "Required"
//         } else if (
//           !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
//         ) {
//           errors.email = "Invalid email address"
//         }
//         return errors
//       }}
//       onSubmit={(values, { setSubmitting }) => {
//         setTimeout(() => {
//           alert(JSON.stringify(values, null, 2))
//           setSubmitting(false)
//         }, 400)
//       }}
//     >
//       {({ isSubmitting }) => (
//         <Form>
//           <Field type="text" name="name" />
//           <ErrorMessage name="name" component="div" />
//           <Field type="text" name="lastname" />
//           <ErrorMessage name="lastname" component="div" />
//           <Field type="email" name="email" />
//           <ErrorMessage name="email" component="div" />
//           <Field type="password" name="password" />
//           <ErrorMessage name="password" component="div" />
//           <button type="submit" disabled={isSubmitting}>
//             Submit
//           </button>
//         </Form>
//       )}
//     </Formik>
